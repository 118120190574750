import React, { useState, useEffect, useRef } from "react";
import lodash from "lodash";
import { useTranslation } from "react-i18next";

import "../../../tailwind.generated.css";
import i18n from "../../../i18n/config";

import FigmaScreenModal, { FigmaScreenHeader, FigmaScreenBody } from "./FigmaScreenModal";
import FigmaPrototypeScreensReport from "./FigmaReportView";
import { ClickmapContext } from '../Clickmaps'
import { HtmlPrototypeScreenReport } from './HtmlPrototypeScreenReport';
import NativePrototypeScreenReport from './NativePrototypeScreenReport';

import useTaskCompletionStats from '../hooks/useTaskCompletionStats';
import { usePrototypeQuery } from "../../UserAccount/Hooks";
import useFigmaContext from '../../Figma/hooks/useFigmaContext';
import useReportScreenTabs from "../hooks/useReportScreenTabs";
import useNativePrototypeData from '../../Figma/hooks/useNativePrototypeData';
import { FigmaCTX } from '../../Figma/contexts/FigmaContext';

import { Action, IFormattedPrototype, OverlayAction, getNodeAsset } from "../../../utils/figma";
import { calcMedian, getFormattedDate } from "../utils";

import { BaseBlock, FigmaBlock } from "../../../models/Test";
import { CanvasSize, IBaseClick, IClick, IClickData, IFigmaResponse } from "../../../models/Response";
import { IPrototypeNode } from '../../Figma/Models';

import FigmaPathExploration from "./PathExploration/FigmaPathExploration";
import { SummaryStats } from "./SummaryStats";
import ReportBlockHeader from "../ReportBlockHeader";
import { RecordResult } from '../../Common/RecordResult';

export interface IReportState {
  byTester: IReportStateByTester[];
  byScreen: IReportStateByScreen;
}
export interface IReportStateByScreen {
  screens: IReportScreenState[];
}
export interface IReportStateByTester {
  answerId: string;
  userAgent: string | undefined;
  date: string;
  givenUp: boolean;
  time: string;
  number: number;
  withVideo?: boolean;
  recordResult: RecordResult | null;
  devicePixelRatio?: number;
  size?: CanvasSize;
  prototypeType: PrototypeType;
  screens: IReportScreenState[];
}

export interface IReportScreenState {
  /** @deprecated This is from old version of figma support */
  name: string;
  nodeKey: string;
  caption: string;
  /** @deprecated This is from old version of figma support */
  image: string;
  pathImagePreview: string;
  path: (string | number)[];
  prevScreenPath: (string | number)[] | null;
  nextScreenPath: (string | number)[] | null;
  clicks: IReportPrototypeClick[];
  overlays?: OverlayAction[];
  stats: any[];
}

export interface IReportPrototypeClick extends IClickData {
  handled: boolean;
  answerId: string;
  timestamp: number;
  action: Action | undefined;
  number: number | undefined;
  clickData: IBaseClick & {
    nodeId: string;
    raw?: IClick;
  };
}

export enum PrototypeType {
  Html = 'Html',
  Native = 'Native',
}

interface IFigmaReportProps {
  responses: IFigmaResponse[];
  isSummaryReport: boolean;
  testId: string;
  block: FigmaBlock & BaseBlock;
  sharingToken?: string;
}

function FigmaReport(props: IFigmaReportProps) {
  const { t } = useTranslation();
  const { responses, block, isSummaryReport = true, } = props;
  const figmaContext = useFigmaContext();
  const [state, setState] = useState<IReportState | null>(null);
  const [activeScreenPath, setActiveScreenPath] = useState<(string | number)[] | null>(null);
  const [currentScreen, setCurrentScreen] = useState<IReportScreenState | null>(null);
  const [screenClicks, setScreenClicks] = useState<IReportPrototypeClick[]>([]);
  const [renderData, setRenderData] = useState<any>(null);
  const [isProtoLoaded, setIsProtoLoaded] = useState<any>(false);

  const { tabs, activeTab } = useReportScreenTabs();
  const lastActionSourceRef = useRef<string | null>(null);
  const pendingPrototypeScreenIdRef = useRef<string | null>(null);

  // byScreen or byTester
  const reportViewMode = lodash.head(activeScreenPath) as string;

  const figmaStats = useTaskCompletionStats(responses);

  // const currentScreen = lodash.get(state, activeScreenPath as string[], null) as IReportScreenState | undefined;
  const prototypeType = block.prototypeLink ? PrototypeType.Native : PrototypeType.Html;
  const showReportModal = activeScreenPath && currentScreen;
  const prototype = usePrototypeQuery(block.prototypeId).data as IFormattedPrototype;
  const nativePrototypeData = useNativePrototypeData(block.prototypeDataUrl).prototypeData;
  const clickmapOwnerId = ['figma', props.testId, block.blockId, currentScreen?.nodeKey, ...(currentScreen?.overlays?.map(o => o.destinationId) || [])].join('-');

  useEffect(() => {
    let nodeNames: { [key: string]: string } = {};
    const pathImagePreview = { ...block.nodeImages };

    if (prototype) {
      nodeNames = lodash.mapValues(prototype.nodesForHtml, (node) => node.name);
      Object.assign(pathImagePreview, lodash.mapValues(prototype.nodesForHtml, (node) => node.image));
    } else {
      nodeNames = block.nodeNames;
    }

    if (nativePrototypeData.data) {
      Object.assign(block, nativePrototypeData.data);
      Object.assign(pathImagePreview, block.nodeImages);
    }

    setState({
      byTester: getFormattedTesters(responses, nodeNames, block.nodeImages, block.fileVersion, prototypeType, block.prototypeScreens, pathImagePreview),
      byScreen: getFormattedScreens(responses, nodeNames, block.nodeImages, block.fileVersion, block.prototypeScreens, pathImagePreview),
    });
  }, [responses, prototype, nativePrototypeData.data]);

  useEffect(() => {
    setScreenClicks([]);
    setRenderData(null);

    // console.log('PATH HAS CHANGED', activeScreenPath);

    if (activeScreenPath) {
      const nextScreen = lodash.get(state, activeScreenPath as string[], null) as IReportScreenState;
      setCurrentScreen(nextScreen);

      // activeScreenPath обновляется дважды при открытии экрана, но currentScreen обновляется только один раз, т.к. берется один и тот же объект
      // и нам не нужно обновлять его еще раз, иначе будет бесконечный цикл с go to screen
      const timeoutId = requestRenderData(nextScreen, figmaContext);
      if (timeoutId) return () => clearTimeout(timeoutId);
    } else {
      pendingPrototypeScreenIdRef.current = null;
      figmaContext?.resetContext();
      // console.log('CLEAN CURREN SCREEEN');
      setCurrentScreen(null);
      setIsProtoLoaded(false);
    }
  }, [activeScreenPath]);

  function requestRenderData(screen: IReportScreenState, figmaCtx: FigmaCTX | null) {
    const clicks = screen?.clicks;
    if (!clicks || clicks.length === 0) return null;

    // filter(c => !c.clickData.raw?.nearestScrollingFrameId)
    const nodesWithoutScrollable = new Set(clicks?.map(c => c.clickData?.raw?.targetNodeId as string));
    const clickedNodes = new Set(clicks?.map(c => c.clickData?.raw?.targetNodeId as string));

    // console.log('REQUESTING DATA FOR', screen.nodeKey, clicks);

    const timeoutId = setTimeout(() => {
      figmaCtx?.getRenderData(clickedNodes, nodesWithoutScrollable);
    }, 500);

    return timeoutId;
  }

  useEffect(() => {
    if (figmaContext) {
      figmaContext.listenToKeydown(onKeydownInFrame);
      figmaContext.onPrototypeLoaded(onPrototypeLoaded);
      figmaContext.onPrototypeReady(onProtoReady);
      figmaContext?.setOnGetRenderDataCallback((data: any) => {
        // console.log("GOT RENDER DATA", currentScreen?.nodeKey, data)
        if (currentScreen?.nodeKey === data.presentedNodeId) {
          setRenderData({ ...data })
        }
      });
    }

    return () => {
      figmaContext?.offPrototypeLoaded(onPrototypeLoaded);
      figmaContext?.offPrototypeReady(onProtoReady);
    }
  }, [currentScreen, figmaContext]);

  // call figmaContext.navigateToNode when currentScreen changes
  useEffect(() => {
    if (currentScreen && figmaContext) {
      if (!figmaContext.isPrototypeLoaded) {
        pendingPrototypeScreenIdRef.current = currentScreen.nodeKey;
      }
      else {
        figmaContext.navigateToNode(currentScreen.nodeKey);
      }
    }
  }, [currentScreen, figmaContext]);


  useEffect(() => {
    // console.log('UPDATING CLICKS', currentScreen?.nodeKey, currentScreen?.clicks);
    if (!currentScreen) {
      // console.log('NO CURRENT SCREEN')
      return;
    }

    if (!figmaContext?.isPrototypeLoaded) {
      // console.log('UPDATING CLICKS: WAITING PROTOTYPE', currentScreen?.nodeKey, currentScreen?.clicks);
      return;
    }
    const screenRenderData = renderData;
    if (!screenRenderData) {
      // console.log('UPDATING CLICKS: NO SCREEN RENDER DATA', currentScreen?.nodeKey, currentScreen?.clicks);
      return;
    }

    let hasCorrectedClicks = false;
    const correctedClicks = currentScreen?.clicks?.map(click => {
      click = lodash.cloneDeep(click);
      const targetNodeId = click.clickData.raw?.targetNodeId || '';
      let renderData = screenRenderData?.elementsWithoutNearestScrolling[targetNodeId];
      const targetNodePos = { x: 0, y: 0, isOnFixed: '', newOwnerId: click.clickData.nodeId };
      // if (click.clickData?.raw?.nearestScrollingFrameId) return click;

      if (renderData) {
        const nearesScrollable = screenRenderData?.nodesRenderData[renderData.newNearest];
        const isFixedLayer = nearesScrollable?.scrollBehavior === 1;
        targetNodePos.x = (click.clickData.raw?.targetNodeMousePosition?.x || 0) + renderData.x - nearesScrollable.absoluteBounds.x;
        targetNodePos.y = (click.clickData.raw?.targetNodeMousePosition?.y || 0) + renderData.y - nearesScrollable.absoluteBounds.y;
        targetNodePos.isOnFixed = isFixedLayer ? renderData.newNearest : '';
        targetNodePos.newOwnerId = renderData.newNearest;
      } else {
        renderData = screenRenderData?.nodesRenderData[targetNodeId];
        if (renderData?.parentData?.parent) {
          targetNodePos.x = (click.clickData.raw?.targetNodeMousePosition?.x || 0) + renderData.absoluteBounds.x - renderData.parentData.parentBounds.x;
          targetNodePos.y = (click.clickData.raw?.targetNodeMousePosition?.y || 0) + renderData.absoluteBounds.y - renderData.parentData.parentBounds.y;
          targetNodePos.newOwnerId = renderData.newNearest;
        }
        else {
          targetNodePos.x = (click.clickData.raw?.nearestScrollingFrameMousePosition?.x || 0) + (click.clickData.raw?.nearestScrollingFrameOffset?.x || 0);
          targetNodePos.y = (click.clickData.raw?.nearestScrollingFrameMousePosition?.y || 0) + (click.clickData.raw?.nearestScrollingFrameOffset?.y || 0);
          // console.log('UNKNOWN ID', targetNodeId, click, targetNodePos)
        }
      }

      if ((click as any).hasCorrectedPosition) return click;
      hasCorrectedClicks = true;
      click.clickData.top = targetNodePos.y;
      click.clickData.left = targetNodePos.x;
      (click as any).hasCorrectedPosition = true;
      (click as any).fixedOwnerId = targetNodePos.isOnFixed;
      (click as any).newOwnerId = targetNodePos.newOwnerId;
      // console.log('CORRECTION', click, targetNodePos)
      return click;
    });
    // console.log('CORRECTING CLICKS', currentScreen?.clicks, correctedClicks);

    if (hasCorrectedClicks) setScreenClicks(correctedClicks);

  }, [currentScreen, renderData, figmaContext]);

  // onPresentedNodeChanged
  function onScreenChanged(screenId: string) {
    // console.log('CALLED onScreenChanged', screenId);
    if (!figmaContext?.isPrototypeLoaded) return;

    setScreenClicks([]);
    setRenderData(null);

    if (lastActionSourceRef.current === 'key') {
      lastActionSourceRef.current = null;
      return;
    }

    const pathCopy = activeScreenPath?.slice();
    if (!pathCopy) return;

    const currentScreenIndex = pathCopy.pop() as number;
    const screens = lodash.get(state, pathCopy as string[], null) as IReportScreenState[] | null;
    if (!screens) return;

    // Find screen with matching nodeKey starting from currentScreenIndex
    // Ищем ближайший экран в истории, который соответствует screenId
    let newIndex = screens.findIndex((s, idx) => idx >= currentScreenIndex && s.nodeKey === screenId);
    if (newIndex === -1) {
      newIndex = screens.findIndex((s, idx) => idx < currentScreenIndex && s.nodeKey === screenId);
    }
    if (newIndex < 0) return;

    pathCopy.push(newIndex);
    setActiveScreenPath(pathCopy);
  }

  function onKeydownInFrame({ key }: any) {
    if (key === "Escape") {
      closeScreen();
    }

    if (key === "ArrowRight") {
      goToNextScreen();
    }

    if (key === "ArrowLeft") {
      goToPrevScreen();
    }
  }

  function onPrototypeLoaded() {
    if (pendingPrototypeScreenIdRef.current) {
      figmaContext?.navigateToNode(pendingPrototypeScreenIdRef.current);
      pendingPrototypeScreenIdRef.current = null;
      setIsProtoLoaded(true);
    }
  }

  function onProtoReady() {
    // console.log('PROTOTYPE READY CALL')
    if (currentScreen) {
      figmaContext?.reInit();
    }
  }


  function goToNextScreen() {
    setCurrentScreen(prevScreen => {
      if (prevScreen?.nextScreenPath) {
        lastActionSourceRef.current = 'key';
        setActiveScreenPath(prevScreen.nextScreenPath);
      }
      return prevScreen;
    });
  }

  function goToPrevScreen() {
    setCurrentScreen(prevScreen => {
      if (prevScreen?.prevScreenPath) {
        lastActionSourceRef.current = 'key';
        setActiveScreenPath(prevScreen.prevScreenPath);
      }
      return prevScreen;
    });
  }

  function closeScreen() {
    setActiveScreenPath(null);
  }

  function getTesterCanvasSize() {
    return reportViewMode === 'byTester' && activeScreenPath ? lodash.get(state, activeScreenPath.slice(0, 2)).size : undefined;
  }

  return (
    <>
      {isSummaryReport && (
        <>
          <div className="figma-report__question flex flex-col gap-1">
            <span className="block captionNoMargin">{t("Task")}</span>
            <ReportBlockHeader questionText={block.text} className="mb-4" />
          </div>
          <SummaryStats stats={figmaStats.commonStatsInfo} className="my-6" />
        </>
      )}
      {
        state && isSummaryReport && (
          <div className="figma-report__path-exploration my-8">
            <div className="flex items-start gap-3 mb-4">
              <div className="flex flex-col gap-1">
                <span className="font-medium text-base+">{t("Path exploration")}</span>
                <span className="text-gray-600 text-sm block">{t("Explore how users navigate through the prototype screens")}</span>
              </div>
            </div>
            <FigmaPathExploration testerResponses={state.byTester} goalScreens={block.goalNode} startNodeId={block.startNodeId || undefined} />
          </div>
        )
      }

      {state && (
        <FigmaPrototypeScreensReport
          blockId={block.blockId}
          onPreviewClick={setActiveScreenPath}
          byTester={state.byTester}
          byScreen={state.byScreen}
          prototype={prototype}
          sharingToken={props.sharingToken}
          isSummaryReport={isSummaryReport}
        />
      )}

      {prototypeType === PrototypeType.Native && showReportModal && (
        <FigmaScreenModal
          onArrowLeft={goToPrevScreen}
          onArrowRight={goToNextScreen}
          onClose={closeScreen}
          resetEventListeners={currentScreen}
        >
          <FigmaScreenHeader
            name={currentScreen?.name || ""}
            caption={currentScreen?.caption || ""}
            onClose={closeScreen}
            tabs={reportViewMode === "byScreen" ? tabs : []}
          />
          <FigmaScreenBody className="items-start justify-start flex-col">
            <NativePrototypeScreenReport
              renderData={renderData}
              clicks={screenClicks || []}
              currentScreenId={currentScreen?.nodeKey || ""}
              figmaScreenStructure={
                block.prototypeScreens?.find((s) => s.id == currentScreen?.nodeKey) as IPrototypeNode
              }
              prototypeOptions={block as any}
              onScreenChanged={onScreenChanged}
              activeTab={activeTab}
              showTabs={reportViewMode === "byScreen"}
              canShowClicksOrder={reportViewMode === "byTester"}
              canShowFirstClickControls={reportViewMode === "byScreen"}
              screenStats={currentScreen?.stats}
              size={getTesterCanvasSize()}
            />
          </FigmaScreenBody>
        </FigmaScreenModal>
      )}

      {prototypeType === PrototypeType.Html && showReportModal && (
        <ClickmapContext
          clickmapOwnerId={clickmapOwnerId}
          imageUrl={lodash.get(state, [...activeScreenPath, "image"])}
          prototype={prototype}
          screen={currentScreen}
        >
          {(clickmap, updateClickmapAreas, figmaImage, isLoading, onLoad) => (
            <FigmaScreenModal
              onArrowLeft={goToPrevScreen}
              onArrowRight={goToNextScreen}
              onClose={closeScreen}
              resetEventListeners={activeScreenPath}
            >
              <FigmaScreenHeader
                name={currentScreen.name}
                caption={currentScreen.caption}
                onClose={closeScreen}
                tabs={reportViewMode === "byScreen" ? tabs : []}
              />
              <FigmaScreenBody>
                <HtmlPrototypeScreenReport
                  screen={currentScreen}
                  responses={responses}
                  prototype={prototype}
                  onLoad={onLoad}
                  clickmap={clickmap}
                  loading={isLoading}
                  onAreaAdded={updateClickmapAreas}
                  key={activeScreenPath?.join("-") || currentScreen.nodeKey}
                  isByScreenMode={reportViewMode === "byScreen"}
                  activeTab={activeTab}
                />
              </FigmaScreenBody>
            </FigmaScreenModal>
          )}
        </ClickmapContext>
      )}
    </>
  );
}

export default FigmaReport;


function getFormattedTesters(responses: IFigmaResponse[], nodeNames: object, nodeImages: object, figmaVersion: string, prototypeType: PrototypeType, prototypeScreens?: any, pathImagePreview?: any) {
  const results: IReportStateByTester[] = [];

  lodash.cloneDeep(responses).forEach((response) => {
    const testerIndex = results.length;
    const screens: IReportScreenState[] = [];
    let totalTimeSpent = 0;

    response.path.forEach((nodeKey, index) => {
      const { clicks = [], timeSpent = 0 } = response.nodeEventData[index] || {};
      totalTimeSpent += timeSpent;
      const sc = prototypeScreens?.find((screen: any) => screen.id === nodeKey);
      const screenName = prototypeScreens
        ? sc?.name || 'Uknown screen'
        : getNodeAsset([nodeKey, figmaVersion], nodeNames)

      screens.push({
        name: screenName,
        nodeKey,
        caption: i18n.t("{{current}} of {{total}}", { current: index + 1, total: response.path.length }),
        image: getNodeAsset([nodeKey, figmaVersion], nodeImages),
        pathImagePreview: getNodeAsset([nodeKey, figmaVersion], pathImagePreview),
        path: ["byTester", testerIndex, "screens", index],
        prevScreenPath: index > 0 ? ["byTester", testerIndex, "screens", index - 1] : null,
        nextScreenPath: index != response.path.length - 1 ? ["byTester", testerIndex, "screens", index + 1] : null,
        clicks: getFormattedClicks(clicks, response.answerId),
        overlays: response.nodeEventData[index]?.overlays,
        stats: [
          [i18n.t("Total clicks"), clicks.length],
          [i18n.t("Missclicks"), clicks.filter(({ handled }) => !handled).length],
          [i18n.t("Time spent"), `${Math.floor(timeSpent / 100) / 10} ` + i18n.t("s")],
        ],
      });
    });

    results.push({
      answerId: response.answerId,
      userAgent: response.userAgent,
      date: getFormattedDate(new Date(response.completedAt)) as string,
      givenUp: response.givenUp,
      time: Math.floor(totalTimeSpent / 100) / 10 + " " + i18n.t("s"),
      number: testerIndex + 1,
      screens,
      withVideo: response.withVideo || false,
      recordResult: response.recordResult,
      devicePixelRatio: response.devicePixelRatio,
      size: response.size,
      prototypeType
    });
  });

  return results;
}

function getFormattedScreens(responses: IFigmaResponse[], nodeNames: object, nodeImages: object, figmaVersion: string, prototypeScreens?: any, pathImagePreview?: any) {
  const results: IReportStateByScreen = {
    screens: [],
  };

  const paths: { nodeKey: string; index: number }[] = [];
  const dataByScreenPath: Record<string, { clicks: any[]; time: number[]; overlays: OverlayAction[] }> = {};

  lodash.cloneDeep(responses).forEach(response => {
    response.path.forEach((nodeKey, index) => {
      const { clicks = [], timeSpent = 0, overlays = [] } = response.nodeEventData[index] || {};
      nodeKey = [nodeKey, ...(response.nodeEventData[index].overlays?.map(o => o.destinationId) || [])].join('-');
      if (dataByScreenPath[nodeKey]) {
        dataByScreenPath[nodeKey].clicks.push(...getFormattedClicks(clicks, response.answerId));
        dataByScreenPath[nodeKey].time.push(timeSpent);
        dataByScreenPath[nodeKey].overlays.push(...overlays);
        dataByScreenPath[nodeKey].overlays = lodash.uniqBy(dataByScreenPath[nodeKey].overlays, o => o.destinationId);
      } else {
        dataByScreenPath[nodeKey] = {
          clicks: getFormattedClicks(clicks, response.answerId),
          time: [timeSpent],
          overlays: overlays,
        };
      }

      paths.push({ nodeKey, index });
    });
  });

  const sortedPaths = lodash.uniqBy(lodash.sortBy(paths, "index"), "nodeKey");

  sortedPaths.forEach((path, index) => {
    const nodeKeyWithOverlay = path.nodeKey;
    const nodeKey = nodeKeyWithOverlay.split('-')[0];
    const event = dataByScreenPath[nodeKeyWithOverlay];
    const averageTime = lodash.sum(event.time) / event.time.length;
    const medianTime = calcMedian(event.time);

    const sc = prototypeScreens?.find((screen: any) => screen.id === nodeKey);
    const screenName = prototypeScreens
      ? sc?.name || 'Uknown screen'
      : getNodeAsset([nodeKey, figmaVersion], nodeNames)

    results.screens.push({
      name: screenName,
      nodeKey,
      caption: i18n.t("{{current}} of {{total}}", { current: index + 1, total: Object.keys(dataByScreenPath).length }),
      image: getNodeAsset([nodeKey, figmaVersion], nodeImages),
      pathImagePreview: getNodeAsset([nodeKey, figmaVersion], pathImagePreview),
      path: ["byScreen", "screens", index],
      prevScreenPath: index > 0 ? ["byScreen", "screens", index - 1] : null,
      nextScreenPath: index != sortedPaths.length - 1 ? ["byScreen", "screens", index + 1] : null,
      clicks: dataByScreenPath[nodeKeyWithOverlay].clicks,
      overlays: dataByScreenPath[nodeKeyWithOverlay].overlays,
      stats: [
        [i18n.t("Respondents"), lodash.uniqBy(dataByScreenPath[nodeKeyWithOverlay].clicks, 'answerId').length],
        [i18n.t("Total clicks"), event.clicks.length],
        [i18n.t("Missclicks"), event.clicks.filter(({ handled }) => !handled).length],
        [i18n.t("Average time"), `${Math.floor(averageTime / 100) / 10} ` + i18n.t("s")],
        [i18n.t("Median time"), `${Math.floor(medianTime / 100) / 10} ` + i18n.t("s")],
      ],
    });
  });

  return results;
}

function getFormattedClicks(clicks: IClick[], answerId: string) {
  const formattedClicks: any = [];

  const canShowClicksOrder = !clicks.some(click => !click.timestamp);

  if (canShowClicksOrder) {
    clicks.sort((a, b) => a.timestamp - b.timestamp);
  }

  const hasNodeId = clicks.some((click) => Boolean(click.nodeId));

  for (let index = 0; index < clicks.length; index++) {
    const click = clicks[index];
    let clickData: any;
    if (hasNodeId) {
      clickData = {
        nodeId: !!click.nearestScrollingFrameId ? click.nearestScrollingFrameId : !!click.targetNodeId ? click.targetNodeId : lodash.get(click, "nodeId"),
        left: lodash.get(click, "x") + lodash.get(click, "nearestScrollingFrameOffset.x", 0),
        top: lodash.get(click, "y") + lodash.get(click, "nearestScrollingFrameOffset.y", 0),
        raw: click,
      };
    } else {
      clickData = {
        left: lodash.get(click, "coordinates.x", 0),
        top: lodash.get(click, "coordinates.y", 0),
      };
    }

    const formattedClick: IReportPrototypeClick = {
      handled: !!click.handled,
      answerId,
      timestamp: click.timestamp,
      action: click.action,
      number: canShowClicksOrder ? index + 1 : undefined,
      clickData
    };

    formattedClicks.push(formattedClick);
  }

  return formattedClicks;
}
